import CampaignsConsts from "../campaignsV2/campaignsConsts"
import Consts from "../app/consts"
import AutomationsConsts from "../automations/automationsConsts"
import FilterTypes, { Filters, SubSourceFilters } from "../common/consts/filterTypesV2"

const AutomationCreationConsts = {
  SOURCES: [
    {
      providerId: CampaignsConsts.FACEBOOK_PROVIDER_ID,
      name: "Facebook",
    },
    {
      providerId: CampaignsConsts.GOOGLE_PROVIDER_ID,
      name: "GoogleAds",
    },
    {
      providerId: CampaignsConsts.TABOOLA_PROVIDER_ID,
      name: "Taboola",
    },
    {
      providerId: CampaignsConsts.PINTEREST_PROVIDER_ID,
      name: "Pinterest",
    },
  ],
  ACTION_TYPES: [
    {
      actionType: Consts.ACTION_TYPE_BID,
      name: AutomationsConsts.ACTION_TYPE_VALUE[Consts.ACTION_TYPE_BID],
    },
    {
      actionType: Consts.ACTION_TYPE_STATUS,
      name: AutomationsConsts.ACTION_TYPE_VALUE[Consts.ACTION_TYPE_STATUS],
    },
    {
      actionType: Consts.ACTION_TYPE_BUDGET,
      name: AutomationsConsts.ACTION_TYPE_VALUE[Consts.ACTION_TYPE_BUDGET],
    },
  ],

  SUB_SOURCE_ACTION_TYPES: [
    {
      actionType: Consts.ACTION_TYPE_SUB_SOURCE_BID,
      name: AutomationsConsts.ACTION_TYPE_VALUE[Consts.ACTION_TYPE_SUB_SOURCE_BID],
    },
    {
      actionType: Consts.ACTION_TYPE_SUB_SOURCE_BLOCK,
      name: AutomationsConsts.ACTION_TYPE_VALUE[Consts.ACTION_TYPE_SUB_SOURCE_BLOCK],
    },
  ],
  VALUE_TYPES: Object.entries(AutomationsConsts.AUTOMATION_VALUE_TYPE_SYMBOL).map(([valueType, name]) => ({
    valueType,
    name,
  })),
  TIME_FRAMES: Object.entries(AutomationsConsts.TIME_FRAME_VALUES).map(([timeFrame, name]) => ({
    timeFrame,
    name,
  })),
  SCHEDULE_TYPES: {
    [AutomationsConsts.REPEATING]: { scheduleType: AutomationsConsts.REPEATING, name: "Repeating", label: "Frequency" },
    [AutomationsConsts.SPECIFIC_HOURS]: {
      scheduleType: AutomationsConsts.SPECIFIC_HOURS,
      name: "Specific hours",
      label: "Hours (UTC)",
    },
  },
  FREQUENCIES: [
    { frequency: "HOURLY", name: AutomationsConsts.FREQUENCY_VALUES.HOURLY },
    { frequency: "THREE_HOURS", name: AutomationsConsts.FREQUENCY_VALUES.THREE_HOURS },
    { frequency: "SIX_HOURS", name: AutomationsConsts.FREQUENCY_VALUES.SIX_HOURS },
    { frequency: "TWELVE_HOURS", name: AutomationsConsts.FREQUENCY_VALUES.TWELVE_HOURS },
    { frequency: "DAILY", name: AutomationsConsts.FREQUENCY_VALUES.DAILY },
  ],
  CONDITIONS_FILTERS: [
    [
      Filters.CAMPAIGN_CLICKS,
      Filters.CAMPAIGN_VISITS,
      Filters.CAMPAIGN_ROI,
      Filters.CAMPAIGN_VISIT_ROI,
      Filters.CAMPAIGN_PROFIT,
      Filters.CAMPAIGN_DAILY_BUDGET,
      Filters.CAMPAIGN_SPEND,
      { ...Filters.CAMPAIGN_BID_TYPE, shouldOverrideFilter: true },
      { ...Filters.AD_TYPE, shouldOverrideFilter: true },
      Filters.CAMPAIGN_ACTIVATION_DATE,
      Filters.CAMPAIGN_CREATE_DATE,
      Filters.CAMPAIGN_INITIAL_ACTIVATION_DATE,
      Filters.CAMPAIGN_LAST_MODIFIED,
      Filters.CAMPAIGN_LAST_MODIFIED_ACTION,
      { ...Filters.CONVERSION_GOAL, shouldOverrideFilter: true },
      Filters.CONVERSION_GOAL_VALUE,
      Filters.CAMPAIGN_CTR,
      Filters.CAMPAIGN_VISIT_PROFIT,
      { ...Filters.ACCOUNT_ID, shouldOverrideFilter: true },
      Filters.CAMPAIGN_RESULTS,
      { ...Filters.ARTICLE_LANGUAGE, defaultOperator: FilterTypes.filterOperators.IN },
      { ...Filters.CAMPAIGN_COUNTRY_GROUP, defaultOperator: FilterTypes.filterOperators.IN },
      Filters.CAMPAIGN_COUNTRY,
    ],
  ],

  CONDITIONS_SUB_SOURCE_FILTERS: [
    [
      SubSourceFilters.SUB_SOURCE_ROI,
      SubSourceFilters.SUB_SOURCE_BOUNCE_RATE,
      SubSourceFilters.SUB_SOURCE_VISITS,
      SubSourceFilters.SUB_SOURCE_CLICKS,
      { ...Filters.ACCOUNT_ID, shouldOverrideFilter: true },
    ],
  ],
  ACTION_STATUSES: [
    { status: CampaignsConsts.CAMPAIGN_STATUS_RUNNING, name: AutomationsConsts.ACTION_STATUS_NAMES.ACTIVE },
    { status: CampaignsConsts.CAMPAIGN_STATUS_PAUSED, name: AutomationsConsts.ACTION_STATUS_NAMES.PAUSED },
  ],
  ACTION_OPERATIONS: [
    { operation: AutomationsConsts.AUTOMATION_OPERATION_INCREASE, name: "Increase" },
    { operation: AutomationsConsts.AUTOMATION_OPERATION_DECREASE, name: "Decrease" },
  ],

  ACTION_LIMIT_BY_OPERATIONS: {
    [AutomationsConsts.AUTOMATION_OPERATION_INCREASE]: {
      [Consts.ACTION_TYPE_BID]: 1,
      [Consts.ACTION_TYPE_BUDGET]: 2000,
    },
    [AutomationsConsts.AUTOMATION_OPERATION_DECREASE]: {
      [Consts.ACTION_TYPE_BID]: 0.01,
      [Consts.ACTION_TYPE_BUDGET]: 2,
    },
  },

  DEFAULT_DAILY_BUDGET_LIMIT: 2000,
  DEFAULT_BID_LIMIT: 1,

  DEFAULT_DAILY_BUDGET_VALUE: 1,
  DEFAULT_BID_VALUE: 0.01,
  DEFAULT_BID_SUB_CENT_VALUE: 0.001,

  ACTION_MAX_BID: 3,
  ACTION_MAX_DAILY_BUDGET: 5000,
  ACTION_MAX_PERCENTAGE: 300,
}

Object.freeze(AutomationCreationConsts)
export default AutomationCreationConsts
