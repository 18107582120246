import { isNullOrUndefined } from "../../../utils/funcUtils"

export class StringFilter {
  static stringIncludes = (x, y) => {
    return !isNullOrUndefined(x) ? x.toLowerCase().includes(y.toLowerCase()) : false
  }

  static stringIncludeOneOf = (x, y) => {
    return !isNullOrUndefined(x) ? y.some((item) => x.toLowerCase().includes(item.toLowerCase())) : false
  }

  static stringIn = (x, items) => {
    return items.includes(x)
  }
  static stringNotIn = (x, items) => {
    return !items.includes(x)
  }

  static stringEqual = (x, y) => {
    return x === y || (isNullOrUndefined(x) && isNullOrUndefined(y))
  }

  static stringNotEqual = (x, y) => {
    return x !== y
  }
}
