import { isNullOrUndefined } from "./funcUtils"
import AutomationsConsts from "../automations/automationsConsts"
import FilterTypes, { FiltersFieldNamesToFilter } from "../common/consts/filterTypesV2"
import AutomationsConditionsTypes from "../automations/automationsConditionsTypes"
import Consts from "../app/consts"
import { history } from "../app/store"
import Moment from "moment/moment"
import Presets from "../common/consts/presets"
import EditableChip from "../common/components/editableChip"
import React from "react"

export function buildActionChangeText(actionType, actionValue, valueType) {
  if (actionType === Consts.ACTION_TYPE_STATUS) {
    return AutomationsConsts.ACTION_STATUS_NAMES[actionValue]
  } else if (actionType === Consts.ACTION_TYPE_SUB_SOURCE_BLOCK) {
    return "Block"
  }
  const actionSymbol = AutomationsConsts.AUTOMATION_VALUE_TYPE_SYMBOL[valueType]
  const absActionValue = Math.abs(actionValue)
  if (actionSymbol === "$") {
    return actionValue >= 0 ? `+$${absActionValue}` : `-$${absActionValue}`
  }

  return actionValue >= 0 ? `+${absActionValue}${actionSymbol}` : `-${absActionValue}${actionSymbol}`
}

export function buildDescriptiveActionChangeText(actionType, actionValue, valueType) {
  const actionName = AutomationsConsts.ACTION_TYPE_VALUE[actionType]

  if (actionType === Consts.ACTION_TYPE_STATUS) {
    return `${AutomationsConsts.ACTION_STATUS_NAMES[actionValue]} campaign`
  } else if (actionType === Consts.ACTION_TYPE_SUB_SOURCE_BLOCK) {
    return actionName
  }

  const actionSymbol = AutomationsConsts.AUTOMATION_VALUE_TYPE_SYMBOL[valueType]
  const isDecrease = String(actionValue).startsWith("-")
  const absActionValue = Math.abs(actionValue)
  const formattedActionValue = actionSymbol === "$" ? `$${absActionValue}` : `${absActionValue}${actionSymbol}`
  return isDecrease
    ? `${actionName} reduce by ${formattedActionValue}`
    : `${actionName} increase by ${formattedActionValue}`
}

export function generateAutomationUrl(automation, hasBaseUrl = false) {
  let automationUrl = "/rule/" + automation.id

  if (hasBaseUrl) {
    automationUrl = Consts.BASE_URL + automationUrl
  }

  return automationUrl
}

export function isAutomationPopupOpen() {
  return history.getCurrentLocation().pathname.includes("/rule/")
}

export function isAutomationPopupLocationState() {
  let locationState = history.getCurrentLocation().state

  return locationState && locationState.isAutomationPopup
}

export function addAutomationsProps(automations) {
  if (!isNullOrUndefined(automations)) {
    return automations.map((automation) => {
      let automationProp = Object.assign({}, automation)
      automationProp.key = automation.id
      automationProp.actionType = automation.action?.action_type
      automationProp.actionValue = automation.action?.action_value
      automationProp.valueType = automation.action?.value_type
      automationProp.actionLimit = automation.action?.action_limit
      automationProp.filterObjects = deserializeConditions(automation?.conditions)
      return automationProp
    })
  }
  return null
}

export function serializeConditions(conditions) {
  // Convert from filter objects to conditions JSON
  let serializedConditions = []
  conditions.forEach((filterType) => {
    filterType.includeFilters.forEach((filterItem) => {
      let condition = convertFilterToCondition(filterItem)
      serializedConditions.push(condition)
    })

    filterType.excludeFilters.forEach((filterItem) => {
      let condition = convertFilterToCondition(filterItem, false)

      serializedConditions.push(condition)
    })
  })

  return serializedConditions
}

function convertFilterToCondition(filterItem, isInclude = true) {
  const fieldName = filterItem.fieldName
  const operator = isInclude
    ? AutomationsConditionsTypes.includeFilterOperatorsToConditionOperators[filterItem.filterOperator]
    : AutomationsConditionsTypes.excludeFilterOperatorsToConditionOperators[filterItem.filterOperator]
  let value = filterItem.filterValue
  if (Array.isArray(filterItem.filterValue)) {
    if (filterItem.filterValue.length === 1) {
      value = filterItem.filterValue[0]
    } else if (filterItem.filterValue.length === 2) {
      if (
        AutomationsConditionsTypes.dateFields.includes(fieldName) &&
        filterItem.filterOperator !== FilterTypes.filterOperators.BETWEEN
      ) {
        value = filterItem.filterValue[0]
      }
    } else if (!filterItem.filterValue[1]) {
      value = filterItem.filterValue[0]
    }
  }

  return {
    field: fieldName,
    operator,
    value,
  }
}

export function deserializeConditions(conditions) {
  // Convert conditions to filter objects
  let filtersMap = new Map()
  conditions.forEach((condition) => {
    const filterType = FiltersFieldNamesToFilter[condition.field]
    if (!isNullOrUndefined(filterType) && condition.field !== "specific_hours") {
      const filter = convertConditionToFilter(condition, filterType)
      const filterKey = filterType.filterType

      if (!filtersMap.has(filterKey)) {
        filtersMap.set(filterKey, filter)
      } else {
        if (filter.excludeFilters.length > 0) {
          filtersMap.get(filterKey).excludeFilters.push(filter.excludeFilters[0])
        } else {
          filtersMap.get(filterKey).includeFilters.push(filter.includeFilters[0])
        }
      }
    }
  })
  return filtersMap
}

function convertConditionToFilter(condition, filterType) {
  let filter = {
    includeFilters: [],
    excludeFilters: [],
  }

  const isExclude =
    AutomationsConditionsTypes.excludeFilterOperators.includes(condition.operator) &&
    filterType.filterValueType !== FilterTypes.filterValueTypes.ID
  let filterValue = Array.isArray(condition.value) ? condition.value.slice() : [condition.value]
  if (
    filterType.filterValueType === FilterTypes.filterValueTypes.DATE &&
    !Presets.FILTER_PRESETS.includes(filterValue[0])
  ) {
    // filter date is None
    if (isNullOrUndefined(filterValue[0])) {
      filterValue = ["", ""]
    } else {
      filterValue[0] = Moment(filterValue[0])

      if (filterValue[1]) {
        filterValue[1] = Moment(filterValue[1])
      } else {
        filterValue = [filterValue[0], filterValue[0]]
      }
    }
  }

  const filterOperator = !isExclude
    ? AutomationsConditionsTypes.conditionOperatorsToIncludeFilterOperators[condition.operator]
    : AutomationsConditionsTypes.conditionOperatorsToExcludeFilterOperators[condition.operator]

  const filterObject = Object.assign({}, filterType, {
    filterValue,
    isExclude,
    filterOperator: parseInt(filterOperator),
  })

  if (!isExclude) {
    filter.includeFilters = [filterObject]
  } else {
    filter.excludeFilters = [filterObject]
  }

  return filter
}

export function buildConditionChipItems(
  automation,
  isAutomationsTable = false,
  chipClasses = "condition-chip-item",
  containerClasses = "condition-container"
) {
  let conditions = []
  if (automation.filterObjects && automation.filterObjects.size > 0) {
    automation.filterObjects.forEach((filterObj) => {
      const filtersToRender = [...filterObj.includeFilters, ...filterObj.excludeFilters]
      filtersToRender.forEach((filter) => {
        let filterChipClasses = chipClasses
        // Add specific CSS to Sub Sources Filters in Automations Table
        if (isAutomationsTable) {
          if (filter.filterType.startsWith("SUB_SOURCE_")) {
            filterChipClasses += " condition-sub-source-chip-item"
          }
        }

        conditions.push(
          <EditableChip
            key={filter.filterName + "-" + filter.filterValue}
            filter={filter}
            useCondition={true}
            classes={filterChipClasses}
            containerClasses={containerClasses}
          />
        )
      })
    })
  }
  return conditions
}

export function formatValueOrReturnOriginal(value) {
  if (Moment.isMoment(value)) {
    return value.format(Consts.CLIENT_SHORT_DATE_FORMAT)
  } else {
    return value
  }
}
