import FilterTypes from "../../consts/filterTypesV2"
import { NumberFilter } from "./numberFilter"
import { DateFilter } from "./dateFilter"
import { StringFilter } from "./stringFilter"
import { ArrayFilter } from "./arrayFilter"

export let FilterFunctions = {}
FilterFunctions[FilterTypes.filterValueTypes.ID] = {}
FilterFunctions[FilterTypes.filterValueTypes.ID][FilterTypes.filterOperators.EQUAL] = StringFilter.stringEqual
FilterFunctions[FilterTypes.filterValueTypes.ID][FilterTypes.filterOperators.NOT_EQUAL] = StringFilter.stringNotEqual
FilterFunctions[FilterTypes.filterValueTypes.NUMBER] = {}
FilterFunctions[FilterTypes.filterValueTypes.NUMBER][FilterTypes.filterOperators.EQUAL] = NumberFilter.numberEqual
FilterFunctions[FilterTypes.filterValueTypes.NUMBER][FilterTypes.filterOperators.MORE_THAN] =
  NumberFilter.numberGreaterThan
FilterFunctions[FilterTypes.filterValueTypes.NUMBER][FilterTypes.filterOperators.LESS_THAN] =
  NumberFilter.numberSmallerThan
FilterFunctions[FilterTypes.filterValueTypes.NUMBER][FilterTypes.filterOperators.BETWEEN] = NumberFilter.numberBetween
FilterFunctions[FilterTypes.filterValueTypes.NUMBER][FilterTypes.filterOperators.NOT_BETWEEN] =
  NumberFilter.numberNotBetween
FilterFunctions[FilterTypes.filterValueTypes.NUMBER][FilterTypes.filterOperators.IN] = NumberFilter.numberIn
FilterFunctions[FilterTypes.filterValueTypes.DATE] = {}
FilterFunctions[FilterTypes.filterValueTypes.DATE][FilterTypes.filterOperators.EQUAL] = DateFilter.dateEqual
FilterFunctions[FilterTypes.filterValueTypes.DATE][FilterTypes.filterOperators.MORE_THAN] = DateFilter.dateAfter
FilterFunctions[FilterTypes.filterValueTypes.DATE][FilterTypes.filterOperators.LESS_THAN] = DateFilter.dateBefore
FilterFunctions[FilterTypes.filterValueTypes.DATE][FilterTypes.filterOperators.BETWEEN] = DateFilter.dateBetween
FilterFunctions[FilterTypes.filterValueTypes.DATE][FilterTypes.filterOperators.NOT_BETWEEN] = DateFilter.dateNotBetween
FilterFunctions[FilterTypes.filterValueTypes.DATE][FilterTypes.filterOperators.NOT_EXIST] = DateFilter.dateNotExist
FilterFunctions[FilterTypes.filterValueTypes.STRING] = {}
FilterFunctions[FilterTypes.filterValueTypes.STRING][FilterTypes.filterOperators.INCLUDE] = StringFilter.stringIncludes
FilterFunctions[FilterTypes.filterValueTypes.STRING][FilterTypes.filterOperators.IN] = StringFilter.stringIncludeOneOf
FilterFunctions[FilterTypes.filterValueTypes.STRING][FilterTypes.filterOperators.IN_ARRAY] = StringFilter.stringIn
FilterFunctions[FilterTypes.filterValueTypes.STRING][FilterTypes.filterOperators.EQUAL] = StringFilter.stringEqual
FilterFunctions[FilterTypes.filterValueTypes.ARRAY] = {}
FilterFunctions[FilterTypes.filterValueTypes.ARRAY][FilterTypes.filterOperators.INCLUDE] = ArrayFilter.arrayIncludes
FilterFunctions[FilterTypes.filterValueTypes.ARRAY][FilterTypes.filterOperators.IN_ARRAY] = ArrayFilter.arrayIncludes
